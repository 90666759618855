<template>
  <div :is="organizationData === undefined ? 'div' : 'b-card'">
    <!-- Alert: No item found -->
    <b-alert variant="danger" :show="organizationData === undefined">
      <h4 class="alert-heading">
        {{ $t("message.errorLoadingData") }}
      </h4>
    </b-alert>

    <template v-if="organizationData">
      <div class="cardTableProject">
        <b-card>
          <h2 class="mb-2">
            {{ $t("organizationEdit") }}
          </h2>

          <validation-observer
            #default="{ handleSubmit }"
            ref="refFormObserver"
          >
            <b-form @submit.prevent="handleSubmit(editOrganization())">
              <b-row cols="12">
                <b-col md="4" class="mt-1">
                  <validation-provider
                    #default="{ errors }"
                    name="company"
                    rules="required"
                  >
                    <b-form-group label-for="nameOpportunities">
                      <template v-slot:label>
                        {{ $t("message.Organization.nameOfYourCompany") }}
                      </template>
                      <b-form-input
                        type="text"
                        v-model="organizationData.company"
                        :state="errors.length > 0 ? false : null"
                      />
                    </b-form-group>
                  </validation-provider>
                </b-col>

                <!-- customers -->
                <b-col md="4" class="mt-1">
                  <validation-provider
                    #default="{ errors }"
                    name="costumer"
                    rules="required|email"
                  >
                    <b-form-group label-for="costumer">
                      <template v-slot:label>
                        {{ $t("message.Organization.emailOfCompany") }}
                      </template>
                      <b-form-input
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        v-model="organizationData.emailCompany"
                        type="email"
                        :state="errors.length > 0 ? false : null"
                      />
                    </b-form-group>
                  </validation-provider>
                </b-col>

                <b-col md="2" class="mt-1">
                  <validation-provider
                    #default="{ errors }"
                    name="cnpj"
                    rules="required"
                  >
                    <b-form-group label="CNPJ" label-for="startOpportunities">
                      <b-form-input
                        v-model="organizationData.cnpj"
                        v-mask="'###.###.####.##'"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        :state="errors.length > 0 ? false : null"
                      />
                    </b-form-group>
                  </validation-provider>
                </b-col>

                <b-col md="2" class="mt-1">
                  <validation-provider
                    #default="{ errors }"
                    name="phone"
                    rules="required"
                  >
                    <b-form-group label-for="startOpportunities">
                      <template v-slot:label>
                        {{ $t("message.Organization.phone") }}
                      </template>
                      <b-form-input
                        v-model="organizationData.phone"
                        type="number"
                        :state="errors.length > 0 ? false : null"
                      />
                    </b-form-group>
                  </validation-provider>
                </b-col>

                <!-- end -->
                <b-col md="4">
                  <validation-provider
                    #default="{ errors }"
                    name="responsiblePerson"
                    rules="required"
                  >
                    <b-form-group label-for="opportunityDate">
                      <template v-slot:label>
                        {{ $t("message.Organization.fullResponsible") }}
                      </template>
                      <b-form-input
                        v-model="organizationData.responsiblePerson"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        :state="errors.length > 0 ? false : null"
                      />
                    </b-form-group>
                  </validation-provider>
                </b-col>

                <b-col md="4">
                  <validation-provider
                    #default="{ errors }"
                    name="responsiblePerson"
                    rules="required"
                  >
                    <b-form-group label-for="value">
                      <template v-slot:label>
                        {{ $t("message.Organization.occupation") }}
                      </template>
                      <b-form-input
                        v-model="organizationData.occupation"
                        type="text"
                        :state="errors.length > 0 ? false : null"
                      />
                    </b-form-group>
                  </validation-provider>
                </b-col>

                <b-col md="4">
                  <b-form-group label-for="value">
                    <template v-slot:label>
                      {{ $t("message.Organization.stateOfProcess") }}
                    </template>
                    <v-select
                      :options="organizationDataStatus"
                      :reduce="(val) => val.value"
                      :clearable="false"
                      input-id="organization-status"
                      v-model="organizationData.status"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    />
                  </b-form-group>
                </b-col>

                <!-- adress -->
              </b-row>
              <b-row cols="12">
                <b-col md="4">
                  <validation-provider
                    #default="{ errors }"
                    name="adress"
                    rules="required"
                  >
                    <b-form-group label-for="value">
                      <template v-slot:label>
                        {{ $t("message.Organization.adress") }}
                      </template>
                      <b-form-input
                        v-model="organizationData.adress"
                        :state="errors.length > 0 ? false : null"
                        type="text"
                      />
                    </b-form-group>
                  </validation-provider>
                </b-col>
                <b-col md="3">
                  <validation-provider
                    #default="{ errors }"
                    name="district"
                    rules="required"
                  >
                    <b-form-group label-for="value">
                      <template v-slot:label>
                        {{ $t("message.Organization.district") }}
                      </template>
                      <b-form-input
                        v-model="organizationData.district"
                        :state="errors.length > 0 ? false : null"
                        type="text"
                      />
                    </b-form-group>
                  </validation-provider>
                </b-col>
                <b-col md="2">
                  <validation-provider
                    #default="{ errors }"
                    name="city"
                    rules="required"
                  >
                    <b-form-group label-for="value">
                      <template v-slot:label>
                        {{ $t("message.Organization.city") }}
                      </template>
                      <b-form-input
                        v-model="organizationData.city"
                        :state="errors.length > 0 ? false : null"
                        type="text"
                      />
                    </b-form-group>
                  </validation-provider>
                </b-col>
                <b-col md="2">
                  <validation-provider
                    #default="{ errors }"
                    name="zipCode"
                    rules="required"
                  >
                    <b-form-group label-for="value">
                      <template v-slot:label>
                        {{ $t("message.Organization.zipCode") }}
                      </template>
                      <b-form-input
                        v-model="organizationData.zipCode"
                        :state="errors.length > 0 ? false : null"
                        type="text"
                      />
                    </b-form-group>
                  </validation-provider>
                </b-col>
                <b-col md="1">
                  <validation-provider
                    #default="{ errors }"
                    name="UF"
                    rules="required"
                  >
                    <b-form-group label="UF" label-for="value">
                      <b-form-input
                        v-model="organizationData.uf"
                        :state="errors.length > 0 ? false : null"
                        type="text"
                      />
                    </b-form-group>
                  </validation-provider>
                </b-col>
              </b-row>

              <br />
              <h5>Dados</h5>
              <br />

              <b-row>
                <b-col md="3">
                  <b-form-group label-for="value">
                    <template v-slot:label>
                      {{ $t("dataLimit") }}
                    </template>
                    <b-form-input type="text" v-model="dataLimitMb" />
                  </b-form-group>
                </b-col>
              </b-row>

              <br />
              <h5>Sistema</h5>
              <br />

              <b-row>
                <b-col md="12">
                  <b-form-group label-for="value">
                    <template v-slot:label>
                      Organização não apartada (Vinculada com root global do
                      sistema)
                    </template>
                    <b-form-checkbox
                      v-model="organizationData.rootControl"
                    >
                    </b-form-checkbox>
                  </b-form-group>
                </b-col>
              </b-row>

              <b-row>
                <b-col>
                  <div class="text-right mt-2 mb-2">
                    <b-button
                      variant="primary"
                      class="mr-1"
                      @click="editOrganization('success', 6000)"
                    >
                      {{ $t("message.buttons.save") }}
                    </b-button>

                    <!--<b-button
                      variant="success"
                      class="ml-1 mr-2"
                      v-b-modal.modal-success
                      @click="generateOrganization('success', 6000)"
                      :to="{ name: 'apps-organization-list' }"
                    >
                      {{ $t("message.buttons.save") }}
                    </b-button>-->

                    <b-modal
                      id="modal-success"
                      ok-only
                      ok-variant="success"
                      ok-title="Accept"
                      modal-class="modal-success"
                      centered
                      title="Success Modal"
                    >
                      <b-card-text>
                        Biscuit chocolate cake gummies. Lollipop I love macaroon
                        bear claw caramels. I love marshmallow tiramisu I love
                        fruitcake I love gummi bears. Carrot cake topping
                        liquorice. Pudding caramels liquorice sweet I love.
                        Donut powder cupcake ice cream tootsie roll jelly.
                      </b-card-text>
                    </b-modal>
                  </div>
                </b-col>
              </b-row>
            </b-form>
          </validation-observer>
        </b-card>
      </div>
    </template>
  </div>
</template>

<script>
import { ref } from "@vue/composition-api";
import {
  BRow,
  BCol,
  BAlert,
  BLink,
  BButton,
  BCardText,
  BCardGroup,
  BCard,
  BModal,
  VBModal,
  BCardBody,
  BCardTitle,
  BCardSubTitle,
  BFormGroup,
  BFormInput,
  BFormTextarea,
  BFormDatepicker,
  BForm,
  BFormCheckbox,
} from "bootstrap-vue";
import getHeader from "@core/utils/auth";
import router from "@/router";
import vSelect from "vue-select";
import flatPickr from "vue-flatpickr-component";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import axios from "@axios";
import URL_API from "@core/utils/env";
import emailLogo from "@/@core/utils/email/email-logo.js";
import emailCss from "@/@core/utils/email/email-css.js";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import store from "@/store";

export default {
  components: {
    BRow,
    BCol,
    BAlert,
    BLink,
    BButton,
    BCardText,
    BCardGroup,
    BCard,
    BCardBody,
    BCardTitle,
    BCardSubTitle,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    vSelect,
    flatPickr,
    BFormDatepicker,
    BModal,
    BForm,
    BFormCheckbox,

    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    "b-modal": VBModal,
  },

  data() {
    return {
      sizeFile: 0,

      userData: store.state.user.userData,

      organizationData: {},

      dataLimitMb: "",

      organizationDataStatus: [
        { label: "Aprovado", value: "Aprovado" },
        { label: "Em análise", value: "Em análise" },
        { label: "Rejeitado", value: "Rejeitado" },
        { label: "Pendente", value: "Pendente" },
      ],
    };
  },

  async created() {
    await this.getData();
  },

  methods: {
    async getData() {
      await axios({
        method: "get",
        url: `${URL_API}organization/tenant/${this.userData.tenant}`,
        headers: getHeader(this.userData),
      }).then((response) => {
        this.organizationData = response.data;
        if (this.organizationData.dataLimit != null) {
          var sizeInMB = (
            this.organizationData.dataLimit /
            (1024 * 1024)
          ).toFixed(2);
          this.dataLimitMb = sizeInMB;
        }
      });
    },

    generateOrganization(variant, timeout) {
      axios({
        method: "post",
        url: `${URL_API}organization`,
        data: this.organizationData,
      });
      this.$toast(
        {
          component: ToastificationContent,
          props: {
            title: this.$i18n.t("message.toast.approved"),
            icon: "CheckIcon",
            variant: "success",
            text: `O cadastro ${this.organizationData.company} foi atualizado com sucesso!`,
            variant,
          },
        },
        {
          timeout,
        }
      );
      /*
      var today = new Date();
      var curHr = today.getHours();

      var welcomeMessage = "";
      if (curHr < 12) {
        welcomeMessage = "Bom dia";
      } else if (curHr < 18) {
        welcomeMessage = "Boa tarde";
      } else {
        welcomeMessage = "Boa noite";
      }

      var bodyAprovado =
        "   <html>" +
        "    <body>" +
        welcomeMessage +
        ", " +
        this.organizationData.responsiblePerson +
        " <p> Status da sua solicitação se encontra como: " +
        this.organizationData.status +
        "</p> " +
        '      <table role="presentation" border="0" cellpadding="0" cellspacing="0" class="body">' +
        "        <tr>" +
        "          <td> </td>" +
        '          <td class="container">' +
        '            <div class="content">' +
        '              <table role="presentation" class="main">' +
        "                <tr>" +
        '                  <td class="wrapper">' +
        '                    <table role="presentation" border="0" cellpadding="0" cellspacing="0">' +
        "                      <tr>" +
        "                        <td>" +
        "                        <h1>A sua solicitação foi aprovada!</h1>" +
        "                          <p>Agora a sua companhia faz parte da plataforma BRXMIND!" +
        " Aguarde até 3 dias úteis para receber o seu acesso por email." +
        "Caso tenha alguma dúvida, por favor, entre em contato com atendimentoweb.braxxy.com.br e lhe responderemos o mais rápido possível " +
        "</p>" +
        '                          <table role="presentation" border="0" cellpadding="0" cellspacing="0" class="btn btn-primary">' +
        "                            <tbody>" +
        "                              <tr>" +
        '                                <td align="left">' +
        '                                  <table role="presentation" border="0" cellpadding="0" cellspacing="0">' +
        "                                    <tbody>" +
        "                                      <tr>" +
        "                                      </tr>" +
        "                                    </tbody>" +
        "                                  </table>" +
        "                                </td>" +
        "                              </tr>" +
        "                            </tbody>" +
        "                          </table>" +
        "                        </td>" +
        "                      </tr>" +
        "                    </table>" +
        "                  </td>" +
        "                </tr>" +
        "              </table>" +
        '              <div class="footer">' +
        '                <table role="presentation" border="0" cellpadding="0" cellspacing="0">' +
        "                  <tr>" +
        emailLogo() +
        '                    <td class="content-block">' +
        '                      <span class="apple-link">BRAXXY © Copyright 2022. Todos os direitos reservados.</span>' +
        "                    </td>" +
        "                  </tr>" +
        "                </table>" +
        "              </div>" +
        "            </div>" +
        "          </td>" +
        "          <td> </td>" +
        "        </tr>" +
        "      </table>" +
        "    </body>" +
        "   </html>" +
        emailCss();

      axios.post(`${URL_API}sending-email`, {
        ownerRef: "Braxxy",
        emailFrom: "brxmind@braxxy.com.br",
        emailTo: this.organizationData.emailCompany,
        subject: "BRXMIND - [Status do seu cadastro]",
        text: bodyAprovado,
      });

      */
    },

    editOrganization(variant, timeout) {
      this.$refs.refFormObserver.validate().then((success) => {
        if (success) {
          var fileSizeInKB = this.dataLimitMb * 1024;
          var fileSizeInMB = fileSizeInKB * 1024;

          this.organizationData.dataLimit = fileSizeInMB;

          axios({
            method: "put",
            url: `${URL_API}organization/${this.organizationData.id}`,
            headers: getHeader(this.userData),
            data: this.organizationData,
          });
          this.$toast(
            {
              component: ToastificationContent,
              props: {
                title: this.$i18n.t("message.toast.updatedB"),
                icon: "CheckIcon",
                variant: "success",
                text: `O cadastro ${this.organizationData.company} foi editado com sucesso!`,
                variant,
              },
            },
            {
              timeout,
            }
          );
        }
      });
    },
  },
};
</script>

<style>
.widthTable {
  width: 100%;
}

.widthTd {
  width: 50%;
}

@media screen and (max-width: 767px) {
  .cardTableProject {
    width: 100%;
  }

  .rTable td {
    display: flex;
    flex-direction: column;
  }
  .rTableWidth {
    width: 100%;
  }

  .rButton {
    margin-top: 3rem;
  }
}
</style>
